.executors_select{
    width: 600px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
}

.select_div{
    max-height: 250px;
    height: auto;
    overflow: auto;
    width:600px;
    margin-left: 40px;
    // z-index: 1;
    box-shadow: -2px 0px 14px -1px rgba(34, 60, 80, 0.2);
    margin-top: 5px;
    border-radius: 5px;
    background-color: white;
    // position:absolute
    top: 60px;
}

.item_sel{
    padding: 5px;
}

.item_sel:hover{
    background-color: rgb(30,144,255);
    border-radius: 5px;
    color: white;
}
.chatMenuMeet {
  z-index: 10;
  width: 747px;
  background-color: #fff;
  // box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  display: grid;
  grid-template-columns: 747px;
  grid-template-rows: 126px 1fr;
  height: 400px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
  top: 0;
}

.chatMenu-headerMeet {
  // height: 126px;
  padding-left: 43px;
  padding-right: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  &__nav {
    border: 1px solid #c4c4c4;
    border-radius: 5px;

    &-item {
      text-align: center;
      padding: 15px 15px;
      // width: 133px;
      height: 100%;
      font-size: 18px;
      color: #292a34;
      cursor: pointer;

      &:not(:last-child) {
        border-right: 1px solid #c4c4c4;
      }
    }
  }

  &__close {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    color: #292a34;
    opacity: 0.5;
  }
}

.chatMenu-header__nav-item-activeMeet {
  background-color: #01b0e9;
  color: white;
}
.chatMenu-footerMeet {
  // height: 126px;
  padding-left: 43px;
  padding-right: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  &__nav {
    &-item {
      text-align: center;
      padding: 15px 15px;
      // width: 133px;
      height: 100%;
      font-size: 18px;
      color: #292a34;
      cursor: pointer;

      &:not(:last-child) {
        border-right: 1px solid #c4c4c4;
      }
    }
  }

  &__close {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    color: #292a34;
    opacity: 0.5;
  }
}

.chatMenu-footer__nav-item-activeMeet {
  background-color: #01b0e9;
  color: white;
  border-radius: 5px;
}

.chatMenu-containerMeet {
  overflow: auto;
  padding-left: 25px;
  padding-bottom: 25px;
  // background-color: #f4f4f4;
}

.input-fullMeet {
  width: calc(100% - 42px);
  display: flex;
  align-items: center;
}

.input-descMeet {
  display: flex;
  flex-direction: column;
  margin-left: 42px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px 4px 0px 0px;
  padding: 16px 21px 16px 21px;
  gap: 12px;

  & > div {
    display: flex;
  }
}

#chatMeet {
  display: flex;
  flex-direction: column;
}
.chat-bottomMeet {
  border-radius: 0px 0px 10px 10px;
  position: relative;
  z-index: 15;
  height: 95px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e1e1;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.15);
  &__files-wrapper {
    max-height: 250px;
    background-color: rgba(225, 225, 225, 0.9);
    padding: 10px;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 1px rgba(34, 60, 80, 0.32);
    position: absolute;
    bottom: 127px;
    left: 22px;
  }
  &__input_otvet {
    align-items: center;
    max-width: 520px;
    border-bottom: 2px solid #cfc9c9;
    position: absolute;
    top: -34px;
    left: 125px;
    display: flex;
    box-shadow: 3px -3px 9px 2px #223c5033;
    padding: 5px 10px 5px 5px;
    background: #e6e8ef;
    border-radius: 15px 20px 15px 0px;
    &_icon {
      cursor: pointer;
      color: #009be7;
      &:hover {
        color: #005b88;
      }
      margin-right: 15px;
      > svg {
        font-size: 35px;
      }
    }
    &_text {
      padding-right: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      flex-direction: column;
      > h5 {
        color: #0480bd;
        height: 15px;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 7px;
      }
      > p {
        color: #000;
      }
    }
    &_iconClose {
      transition: all 0.2s;
      cursor: pointer;
      margin-left: 10px;
      border-radius: 50%;
      padding: 5px;
      float: right;
      &:hover {
        color: red;
      }
    }
  }
}

.chat-contentMeet {
  flex-grow: 1;
  max-height: 300px;
  overflow-y: scroll;
  height: calc(100vh - 325px);
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 15px 0px 0px 0px;
  &_data {
    margin: 10px auto 35px;
    text-align: center;
    > p {
      color: rgba(41, 42, 52, 0.54);
      font-weight: 400;
      font-size: 15px;
      line-height: 13px;
    }
  }
}

.chat-content-scrollMeet {
  min-height: 300px;
  padding: 12px 21px;
  background: rgba(196, 196, 196, 0.23);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.chat-btnMeet {
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-color: transparent;
  border: none;

  border-radius: 10px;
  transition: all 0.2s;
  &:focus-visible {
    outline: none;
  }
  &:hover {
    background-color: #e9e9e9;
  }
  > img {
    cursor: pointer;
    padding: 5px;
  }

  &__file-action {
    background-color: #a0e8ff;
  }
}

#send-messageMeet {
  background-color: #01b0e9;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-messageMeet {
  position: relative;
  // width: 498px; фикисирвоанная ширина дял сообщений
  display: flex;
  background: #fefefe;
  border-radius: 15px;
  margin-bottom: 15px;
  padding: 15px 17px 23px 23px;
  &_img {
    margin-right: 8px;
    background: #01b0e9;
    min-width: 25px;
    height: 25px;
    border-radius: 50%;
    align-self: flex-start;
    > img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }
  }

  &_text {
    & h4 {
      height: 15px;
      font-weight: 500;
      font-size: 16px;
      line-height: 13px;
      color: #292a34;
      margin-top: 5px;
      margin-bottom: 10px;
    }

    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 125%;
      color: #292a34;
    }
  }
  span {
    color: #01b0e9;
  }
  &__spanData {
    transition: all 0.2s;
    opacity: 1;
    position: absolute;
    right: 12px;
    bottom: 0px;
    line-height: 25px;
    color: #8d8d8d !important;
    font-size: 11px;
    text-align: left;
  }
  &:hover &__spanData {
    opacity: 0;
  }
  &__answer {
    text-align: left;
    display: none;
    font-size: 12px;
    cursor: pointer;
    color: #1baaf0 !important;
    margin-left: 8px;
    margin-right: 15px;
  }
  &__deleted {
    float: right;
    display: none;
    font-size: 12px;
    cursor: pointer;
    margin-right: 5px;
    color: #e57777 !important;
  }
  &:hover &__deleted {
    display: inline;
    opacity: 1;
    &:hover {
      color: red !important;
    }
  }
  &:hover &__answer {
    display: inline;
    opacity: 1;
    &:hover {
      color: #009be7 !important;
    }
  }
  &__div-Otev {
    max-width: 400px;
    border-left: 3px solid #1baaf0;
    padding-left: 8px;
    flex-direction: column;
    overflow: hidden;
    height: 50px;
    display: flex;
    justify-content: space-between;
    > p {
      padding: 10px 0px;
      font-size: 16px;
    }
  }
}

.chat-message-myMeet {
  max-width: 518px;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  min-width: 180px;
  min-height: 85px;
  display: block;
  background: #97c9da;
  margin-left: auto;
  a {
    color: #292a34;
  }
}
.strangers_messageMeet {
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  min-width: 180px;
  min-height: 85px;
}

.chat-message_imgMeet {
  text-align: center;
  color: white;
  padding-top: 6px;
}

.head_meassageMeet {
  width: fit-content;
}

.blockOfFormMeet{
  border: 1px solid grey;
  padding-bottom: 15px;
  pointer-events: none;
  margin-bottom: 10px;
}

.blockOfFormAutorMeet {
  // border: 1px solid grey;
  padding-bottom: 15px;
  pointer-events: none;
  margin-bottom: 10px;
}

.deleteArrow selectMeet {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -o-appearance: none;
}
.deleteArrow textareaMeet {
  resize: none;
}

.chat-message__file-downloadMeet {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 7px;
  margin-bottom: 0px;
  box-shadow: 0px 1px 4px #00000026;
  padding: 5px;
  border-radius: 0px 10px 10px 0px;
  &_deletedBtn {
    height: 22px;
    width: 22px;
    cursor: pointer;
    color: red;
    margin-left: 5px;
    padding: 4px 7px;
    background-color: #e5e5e5;
    border-radius: 50%;
    &:hover {
      background-color: #b3b3b3;
    }
  }
  &_btnSubmit {
    background-color: #01b0e9;
    padding: 7px 10px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 10px;
    color: #fff;
    &:hover {
      background-color: #0281ac;
    }
  }

  &_in-message {
    border-radius: 4px 10px 6px 13px;
    transition: all 0.3s;
    width: 350px;
    padding-right: 10px;
    > span {
      line-height: 22px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:hover {
      background: #e1e1e1 !important;
      border-radius: 16px 10px 6px 4px;
    }
  }
}

.chat-message-systemMeet {
  width: fit-content;
  display: block;
  background: #d2d2d2;
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  span {
    color: #292a34;
  }
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}

.chat_menu_nosample_buttonMeet {
  height: 70px;
  border-block-start: 1px solid grey;
}
.oneRowTwoElem {
  display: flex;
  align-items: center;
  & > div:first-child {
    width: 294px;
  }
  & > div:last-child {
    margin-left: 96px;
  }
}
.blockChangeMeet {
  cursor: default;
  pointer-events: none;
}

.window_appealMeet {
  position: absolute;
  bottom: 73px;
  background-color: white;
  width: 500;
  left: 100px;
  overflow-y: scroll;
  max-height: 150px;
  border-radius: 5px;
}

.item_appealMeet {
  width: 500px;
  height: 25px;
  padding: 10px;
  cursor: pointer;
  align-items: center;
  &:hover {
    background: #d2d2d2;
  }
}
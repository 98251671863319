.menu {
    width: 337px;
    padding: 15px 20px;
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    &__wrapper {
        position: relative; } }

.menu__head-buttons {
    display: flex;
    height: 40px;
    gap: 15px;
    justify-content: space-between; }
.menu__head-button {
    width: 134px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24p;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #292A34;
    padding: 0 12px; }

.menu__head-button:last-child {
    color: #fff;
    background: #01B0E9;
    width: 161px;
    border: 1px solid #01B0E9;
    border-radius: 4px; }

.footer-head {}

.menu_footer {
    margin-top: 55px; }

.hide {
    margin-top: 30px; }

.title {
    font-size: 18px;
    padding-left: 10px; }

.show_events {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 10px 0;
    font-size: 14px; }

.show_meetings {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 25px 10px 0;
    font-size: 14px; }

.show_tasks {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 25px 10px 0;
    font-size: 14px; }

/* Скрыть оригинальный чекбокс */
.checkbox-container input {
    display: none; }

/* Стилизация контейнера */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px; }

/* Стилизация галочки */
.checkmark {
    position: absolute;
    top: -8px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #eee;
    border: 2px solid gray; }

/* При нажатии на контейнер изменяем цвет галочки */
.show_events .checkbox-container input:checked ~ .checkmark {
    background-color: #D3D3D3;
    border: none; }

.show_meetings .checkbox-container input:checked ~ .checkmark {
    background-color: #9ADCF2;
    border: none; }

.show_tasks .checkbox-container input:checked ~ .checkmark {
    background-color: #AECBEC;
    border: none; }

/* Создаем форму галочки */
.checkmark:after {
    content: "";
    position: absolute;
    color: gray; }


/* Отображаем форму галочки, когда чекбокс отмечен */
.checkbox-container input:checked ~ .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg); }

.prioritets {
    margin-top: 40px; }

.prioritet_container {
    display: flex;
    padding-left: 10px;
    margin-top: 20px;
    margin-bottom: 50px;
    gap: 30px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.15px;

    color: #292A34;
    flex-wrap: wrap; }
.prioritet {
    display: flex;
    margin-top: 10px;
    gap: 10px;
    align-items: center; }

.red_oval {
    width: 22px;
    height: 22px;
    background: #F44336; }
.green_oval {
    width: 22px;
    height: 22px;
    background: #FFD600; }
.gray_oval {
    width: 22px;
    height: 22px;
    background: #D3D3D3; }

.orange_oval {
    width: 22px;
    height: 22px;
    background: #FF9900; }

.content-switcher {
    position: absolute;
    bottom: 12px;
    left: 22px;

    width: 44px;
    height: 44px;
    background: #FFFFFF;
    border: 0.5px solid #C4C4C4;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease 0s; }

.switcher-arrow-close {
    transform: rotate(90deg); }
.switcher-arrow-open {
    transform: rotate(-90deg); }

.content-switcher:hover {
    background: rgb(239, 239, 239);
    border: 1px solid #C4C4C4; }

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&family=Roboto&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  cursor: default;
  min-height: 100vh;
  max-width: 100vw;
  height: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style-type: none;
}

ul {
  margin-left: 0;
  padding-left: 0;
}

.bussines-page {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
}

.right-content {
  width: calc(100% - 12px);
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none;
}

.blue-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #01b0e9;
  padding: 12px 35px;
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #01b0e9;
}

.blue-btn__disabled2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(196, 196, 196, 0.4);
  padding: 12px 35px;
  color: rgba(41, 42, 52, 0.5);
  font-size: 18px;
  line-height: 24px;
  border-radius: 4px;
  cursor: default;
  border: 1px solid rgba(196, 196, 196, 0.4);
}

.blue-btn__disabled {
  background-color: rgba(196, 196, 196, 0.4);
  color: rgba(41, 42, 52, 0.5);
  cursor: default;
  border: 1px solid rgba(196, 196, 196, 0.4);
}

.defualt__btn {
  font-weight: 400;
  font-size: 14px;
  color: #292a34;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.white-btn {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  color: #292a34;
}

.white-btn__disabled {
  color: grey;
  cursor: default;
}

.blue-btn:hover {
  background-color: #6db7d0;
  border-color: #6db7d0;
}

.defualt__btn:hover {
  color: #01b0e9;
}

.defualt__btn:active {
  color: #01b0e9;
}

@import './reset';
@import './mixins';
@import './keyframes';
@import './variables';

.MuiPickerDTTabs-tabs, .MuiPickersClockPointer-pointer,
.MuiPickersToolbar-toolbar, .MuiPickersClock-pin,
.MuiPickersClockPointer-noPoint, .MuiPickersDay-daySelected {
  background-color: $blue-main !important; }

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #1f8bc2 !important; }

.MuiPickersClockPointer-thumb {
  border-color: $blue-main !important; }

.MuiButton-textPrimary, .MuiTypography-colorPrimary {
  color: $blue-main !important; }

* {
  box-sizing: border-box; }

html {
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px; }

  -webkit-scrollbar-track: {
    background-color: red; }

  ::-webkit-scrollbar-thumb {
    background-color: #E0E0E0;
    border-radius: 50px;
    &:hover {
      background-color: #A8A8A8; } }
  ::-webkit-scrollbar-track-piece {
    background-color: transparent; } }
body {
  font-family: 'Roboto', serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  min-height: 100vh; }

.main_title {
  color: #000000;

  > div:first-child {
    font-size: 20px;
    color: $black-darkest; }

  .countUsers {
    color: $black-middle; } }

.panel_main_title {
  font-size: 20px;
  color: #000000; }

.sub_title {
  font-size: 13px;
  color: $black; }

.button {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  cursor: pointer;
  min-width: 77px;
  padding: 8px;
  height: 32px;
  font-size: 13px;
  line-height: 16px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  border-radius: 6px;
  //background-color: $black-lighter

  &.lg {
    height: 40px; }

  &.filled {
    color: $black-white;

    &.blue {
      background-color: $blue-main;

      &:hover {
        background-color: $blue-darker; } }

    &.red {
      background-color: $red-main;

      &:hover {
        background-color: $red-darker; } }

    &.disabled {
      background-color: $black-lighter;

      &:hover {
        background-color: $black-lighter;
        cursor: default; } } }

  &.outlined {
    color: $blue-darker;

    &.blue {
      border: 1px solid $black-lighter;

      &:hover {
        border: 1px solid $blue-darker; } }

    &.red {
      border: 1px solid $red-main;

      &:hover {
        border: 1px solid $blue-darker; } }

    &.disabled {
      border: 1px solid $black-lighter;
      color: $black-lighter;

      &:hover {
        border: 1px solid $black-lighter;
        cursor: default; } } }

  &.borderless {

    &.red {
      color: $red-main;

      &:hover {
        color: $red-darker; } }

    &.blue {
      color: $blue-main;

      &:hover {
        color: $blue-darker; } }

    &.disabled {
      color: $black-lighter;

      &:hover {
        cursor: default; } } } }

.btn {
  border-radius: 6px;
  height: 40px;
  cursor: pointer;
  // width: 100%
  @include flex(center, center);

  &.primary {
    background: $blue-main;
    transition: background .2s ease, box-shadow .2s ease;
    color: #fff;

    //&:hover
    //  box-shadow: 0 0 20px rgba(0, 0, 0, 0.12)

    &:active {
      background: $light_accent; }

    &.disabled {
      opacity: .4;
      pointer-events: none; } }

  &.secondary {
    border: $grey_border;
    transition: color .2s ease, border .2s ease, background .2s ease;

    &:hover, &.active {
      color: $blue-main;
      border: 1px solid $blue-main; }

    &:active {
      background: $blue_background; }

    input[type=text] {
      padding: 0;
      cursor: inherit; } }

  &.add_btn {
    border: 1px solid $blue-main;
    color: $blue-main;

    .add_icon {
      @include size(16px, 16px);
      //display: inline-table
      background-image: url('../img/plus-1.svg');
      background-position: -5px -5px;
      background-size: 26px;
      background-repeat: no-repeat;
      margin-right: 8px; } }

  &.in_load {
    color: $blue-main;
    position: relative;
    pointer-events: none;

    &::before {
      z-index: 2;
      content: '';
      margin: auto;
      border-radius: 50%;
      border: 3px solid #fff;
      border-top-color: $blue-main;
      border-left-color: $blue-main;
      opacity: .6;
      animation: spin .6s linear infinite;
      pointer-events: none;
      @include size(14px, 14px);
      @include absolute; } } }

.dots_button {
  border: 1px solid rgba(27, 170, 240, 0.3);
  border-radius: 6px;
  padding: 0 4px;
  margin-left: 8px;
  cursor: pointer;
  background-color: #ffffff;
  position: relative;
  transition: background-color .2s ease;
  @include flex(space-between, center);
  @include size(22px, 22px);

  &:hover {
    background-color: $blue_background; }

  &:active, &.active {
    background-color: rgba(27, 170, 240, 0.3); }

  .dot {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    @include size(3px, 3px); } }

.is_drag_enter {
  &::before {
    content: '';
    background-color: rgba(243, 250, 254, 0.9);
    border-radius: 4px;
    background-image: url('../img/upload_cloud.svg');
    background-repeat: no-repeat;
    background-position: center 51px;
    @include size(100%, 100%);
    @include absolute; }

  &::after {
    content: 'Перетяните файлы сюда или кликните ';
    color: $blue-main;
    font-size: 16px;
    @include absolute(111px); } }

.close_btn {
  //border-radius: 50%
  //border: 1px solid rgba(33, 33, 33, 0.5)
  background: url('../img/x.svg') center no-repeat;
  background-position: -5px -3px;
  opacity: .7;
  cursor: pointer;
  transition: opacity .3s ease;
  @include size(24px, 24px);

  &:hover {
    opacity: 1; } }

.a_ {
  &center {
    text-align: center; }

  &left {
    text-align: left; }

  &right {
    text-align: right; } }

.input_div {
  //color: $black-darkest
  border-radius: 6px;
  position: relative;
  border: 1px solid $black-lighter;

  // *
  //   color: $black-darkest

  &:hover {
    border: 1px solid $blue-lighter; }

  &:focus-within {
    border: 1px solid $blue-main;
    -webkit-box-shadow: 0 0 0 2px $blue-lighter;
    box-shadow: 0 0 0 2px $blue-lighter; }

  &.text_exist {
    background: $blue-lightest;
    border: 1px solid $blue-lighter;

    input, textarea {
      background: $blue-lightest; }

    &:hover {
      border: 1px solid $blue-main; }

    &:focus-within {
      background: $black-white;
      border: 1px solid $blue-main;

      input, textarea {
        background: $black-white; } } }

  input, textarea {
    ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
      color: $black-middle; } }

  .secondary {
    height: 40px;
    display: flex;
    align-items: center;

    .current_item_text {
      padding: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } }

  input {
    //height: 38px
    padding: 12px;
    border-radius: 6px;
    box-shadow: none;
    width: 100%;
    @include ellipsis; }

  &.with_icon {
    padding-right: 45px;

    .eye_icon {
      background-image: url('../img/password_eye.png');
      cursor: pointer;
      @include size(16px, 10px);
      @include absolute(calc(50% - 5px), 20px); } }

  &.disabled {
    pointer-events: none;
    background: $disabled;

    input {
      color: $disabled_text;
      background-color: transparent; } } }

.invalid {
  border-color: red !important;

  input {
    color: red; } }

.textarea_div {
  border-radius: 6px;
  overflow: hidden;
  border: $grey_border;
  margin-top: 16px;
  height: 118px;

  textarea {
    padding: 11px 12px;
    resize: none;
    font-family: 'Roboto';
    background: transparent;
    @include size(100%, 100%); }

  &.disabled {
    background: $disabled;
    pointer-events: none;

    textarea {
      color: $disabled_text; } } }

.cross {
  background: url('../img/red_union.png');
  cursor: pointer;
  transition: opacity .2s ease;
  @include size(11px, 10px);
  @include absolute; }

.grid {
  display: grid;
  grid-template-columns: 94px repeat(5, 1fr) repeat(6, 94px);
  column-gap: 24px;
  align-items: center;

  .js_start {
    justify-self: start; }

  .js_center {
    justify-self: start; }

  .js_end {
    justify-self: start; }

  .task_id {
    justify-self: end; }

  .name_cell {
    //grid-column: 2/6
    justify-self: start;
    //width: 100%
    //overflow: hidden
    @include ellipsis();
    max-width: calc(100vw - 1100px); }

  .task_status_wrapper {
    //grid-column: 7/9
    grid-column: 8;
    justify-self: center;
    //width: 100%
    width: 104px; }

  .partition_of_task {
    div {
      @include ellipsis(); } }

  .project_of_task {
    div {
      @include ellipsis(); } }

  .task_dead_line {
    justify-self: end; }

  .task_executor {
    justify-self: center; }

  .task_load {
    justify-self: start; }

  .task_priority {
    justify-self: center; } }

.blue_arrow {
  background-image: url('../img/blue_arrow.png');
  transition: transform .2s ease, background-color .4s ease;
  min-width: 12px;
  @include size(12px, 8px);

  &.rotated {
    transform: rotate(180deg); } }

.snackbar_cross {
  padding: 0 8px;
  cursor: pointer; }

.custom_select {
  position: relative;
  color: #454A63;

  .btn {
    justify-content: space-between; }
  //padding: 0 12px

  .is_open {
    border-color: $blue-main; }

  .disabled {
    background: $disabled;
    color: $disabled_text;
    pointer-events: none;

    .blue_arrow {
      display: none; } }

  .input_search_box {
    width: 100%;
    display: flex;
    justify-content: space-between;

    svg {
      width: 22px;
      height: 22px;
      color: rgba(0, 0, 0, 0.34);

      &::placeholder {
        color: rgba(0, 0, 0, 0.34); } } } }

.select_items {
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0 0 5px #E0E2F2;
  width: 100%;
  padding: 9px 0;
  z-index: 100;
  max-height: 250px;
  overflow: auto;
  transform: translateY(100%);
  @include absolute(initial, initial, -10px, initial); }

.custom_select_item {
  height: 26px;
  line-height: 26px;
  padding: 0 10px;
  transition: background .2s ease;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #454A63;
  @include ellipsis;

  &:hover {
    background: rgba(148, 148, 148, 0.1); }

  &.selected_item {
    background: $blue_background; }
  &.add_project_btn {
    color: #37b4f1;
    font-weight: 400; } }

.custom_select_users_item {
  height: 26px;
  line-height: 26px;
  padding: 0 10px;
  transition: background .2s ease;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #454A63;
  @include ellipsis;

  &:first-child {
    color: #37b4f1; }

  &:hover {
    background: rgba(148, 148, 148, 0.1); }

  &.selected_item {
    background: $blue_background; }
  &.add_project_btn {
    color: #37b4f1;
    font-weight: 400; } }

main {
  // max-width: 1280px
  min-width: 1024px;
  margin: 0 auto;

  &.blur {
    filter: blur(0px);
    overflow: hidden; } }

.header_search {
  grid-column: 10/12; }

.main_actions, .table {
  padding: 0 40px; }

.main_actions {
  justify-content: space-between;
  padding: 23px 18px;
  height: 101px; }

.table {
  position: relative;
  height: calc(100vh - 170px);
  overflow-y: auto;

  &.table_10 {
    .grid {
      grid-template-columns: 94px repeat(6, 1fr) repeat(8, 94px); } }

  &.table_9 {
    .grid {
      grid-template-columns: 94px repeat(6, 1fr) repeat(7, 94px); } }

  &.table_8 {
    .grid {
      grid-template-columns: 94px repeat(6, 1fr) repeat(6, 94px); } }

  &.table_7 {
    .grid {
      grid-template-columns: 94px repeat(6, 1fr) repeat(5, 94px); } }

  &.table_6 {
    .grid {
      grid-template-columns: 94px repeat(6, 1fr) repeat(4, 94px); } }

  &.table_5 {
    .grid {
      grid-template-columns: 94px repeat(6, 1fr) repeat(3, 94px); } }

  &.table_4 {
    .grid {
      grid-template-columns: 94px repeat(6, 1fr) repeat(2, 94px); } }

  &.table_3 {
    .grid {
      grid-template-columns: 94px repeat(6, 1fr) repeat(1, 94px); } }

  &.table_2 {
    .grid {
      grid-template-columns: 94px repeat(6, 1fr) repeat(0, 94px); } }

  &.table_id {
    &.table_9 {
      .grid {
        grid-template-columns: 94px repeat(7, 1fr) repeat(7, 94px); } }

    &.table_8 {
      .grid {
        grid-template-columns: 94px repeat(7, 1fr) repeat(6, 94px); } }

    &.table_7 {
      .grid {
        grid-template-columns: 94px repeat(7, 1fr) repeat(5, 94px); } }

    &.table_6 {
      .grid {
        grid-template-columns: 94px repeat(7, 1fr) repeat(4, 94px); } }

    &.table_5 {
      .grid {
        grid-template-columns: 94px repeat(7, 1fr) repeat(3, 94px); } }

    &.table_4 {
      .grid {
        grid-template-columns: 94px repeat(7, 1fr) repeat(2, 94px); } }

    &.table_3 {
      .grid {
        grid-template-columns: 94px repeat(7, 1fr) repeat(1, 94px); } }

    &.table_2 {
      .grid {
        grid-template-columns: 94px repeat(7, 1fr) repeat(0, 94px); } } }

  &.in_load {
    overflow: hidden;

    &::before {
      content: '';
      background: #fff;
      z-index: 3;
      @include size(100%, calc(100% + 10px));
      @include absolute(-5px, 0); }

    &::after {
      content: '';
      border-radius: 50%;
      border: 3px solid $blue-main;
      z-index: 3;
      border-bottom-color: #fff;
      border-left-color: #fff;
      animation: spin .5s linear infinite;
      transform: translateX(-50%);
      @include absolute(250px, false, false, 50%);
      @include size(16px, 16px); } } }

.project_select_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .user_project_select {
    display: inline-block;
    margin-right: 16px;

    .select_items {
      min-width: 400px; } }

  .project_menu {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    background: #fff;
    z-index: 100;
    @include absolute(25px, initial, initial, 0);
    @include size(174px, 50px);
    @include flex;

    div {
      padding-left: 48px;
      position: relative;
      align-self: center;
      font-size: 15px;
      color: $black;
      transition: background-color .2s ease;
      line-height: 50px;
      @include size(100%, 50px);

      &:hover {
        background-color: $blue_background; }

      &::before {
        content: '';
        background: url('../img/edit_pen.png');
        transform: translateY(-50%);
        @include absolute(50%, initial, initial, 18px);
        @include size(13px, 13px); } } } }

.head_of_page {
  position: absolute;
  top: 45px;
  z-index: 5;
  width: 400px;
  background-color: white; }

.user_project_select {
  //padding-left: 32px
  // max-width: 300px
  //flex-direction: column
  flex-wrap: wrap;
  @include flex;

  .name_with_subtitle {
    display: inline-block;

    .name_arrow {
      cursor: pointer;

      &:hover {
        .arrow_wrapper {
          background-color: $blue_background; } } }

    .description {
      display: flex;
      cursor: pointer;

      .sub_title {
        margin-right: 8px;

        &.active {
          color: $blue-main;
          cursor: default; } } } }

  .arrow_wrapper {
    //@include absolute(0, false, false, 0)
    margin-left: 5px;

    &.highlighted {
      background-color: $blue_background; } }

  .main_name {
    color: #000000;
    font-size: 20px;
    margin-bottom: 5px;
    @include ellipsis(2); }

  .sub_title {
    color: rgba(0, 0, 0, 0.34); } }

.arrow_wrapper {
  border-radius: 5px;
  transition: background-color .2s ease;
  @include flex(center, center);
  @include size(24px, 23px);

  &.highlighted {
    background-color: $blue_background; } }

.name_arrow {
  display: flex; }

.tasks_actions {
  height: 80px;
  margin-bottom: 12px;
  position: relative;
  // max-width: 1280px
  // margin: 0 auto

  .user_project_select {
    //grid-column: 1/9
    grid-column: 1/7; }

  .display_type {
    grid-column: 11/12;

    .select_items {
      width: 100%; } }

  .add_btn {
    grid-column: 12/13; }

  .select_items {
    width: 400px; }

  // .sub_actions
  //   //grid-column: 9/11
  //   grid-column: 7/11
  //   @include flex(flex-end, center)

  //   .columns_select
  //     @include size(32px, 32px)
  //     margin-right: 30px
  //     //color: rgba(0, 0, 0, 0.34)
  //     cursor: pointer
  //     background-image: url('../img/bi_layout-three-columns_grey.svg')
  //     background-position: 0 0
  //     background-size: 30px
  //     background-repeat: no-repeat

  //     &.active
  //       background-image: url('../img/bi_layout-three-columns_blue.svg')

  //// .visualization_button
 }  ////   color: rgba(0, 0, 0, 0.34)

.filter_btn {
  //  grid-column: 10/11
  //    это временно, пока кнопка соседняя скрыта
  //    grid-column: 11/12
  margin: 0 8px;
  padding: 0 8px;

  &:hover {
    background-color: rgba(27, 170, 240, 0.05); }

  svg {
    color: $blue-main; }

  &.active {
    background-color: rgba(27, 170, 240, 0.05);
    color: $blue-main;
    border: none; }
  .MuiSvgIcon-root {
    margin-right: 5px; }

  // .filter_plate
  //   color: black
  //   cursor: default
  //   padding: 16px
  //   width: 520px
  //   position: absolute
  //   top: 75px
  //   right: 25px
  //   //background-color: greenyellow
  //   z-index: 3
  //   border-radius: 12px
  //   //right: 25px
  //   background: #ffffff
  //   box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.09)
  //   flex-direction: column
  //   @include flex

  //   .container_headline_filter
  //     display: flex
  //     //padding: 15px 0px
  //     //height: 40px
  //     .contents_key_headline
  //       width: 216px
  //       height: 40px
  //       padding: 12px 16px

  //     .contents_value_headline
  //       width: 216px
  //       display: flex
  //       padding: 12px 16px

  //       div
  //         padding: 0 10px
  //         cursor: pointer

  //         &.active
  //           color: $blue-main
  //           cursor: default

  //   .container_filter_row
  //     display: flex
  //     margin-bottom: 8px

  //     &:last-child
  //       margin-bottom: 0

  //     .filter_key
  //       width: 216px

  //       .select_items
  //         width: 216px

  //     .filter_value
  //       width: 216px
  //       margin: 0 8px

  //       .select_items
  //         width: 216px

  //     .addNewFilterButton
  //       width: 40px
  //       height: 40px
  //       border: 1px solid $blue-main
  //       color: $blue-main
  //       border-radius: 6px
  //       box-sizing: border-box
  //       padding: 7px
  //       cursor: pointer

  //   .applied_filters
  //     margin-top: 10px
  //     display: flex
  //     flex-wrap: wrap

  //     .applied_filter_item
  //       display: flex
  //       background: $blue_background
  //       width: 227px
  //       height: 32px
  //       padding: 8px
  //       border-radius: 20px
  //       color: $blue-main
  //       font-family: Roboto, serif
  //       font-style: normal
  //       font-weight: normal
  //       font-size: 14px
  //       line-height: 16px
  //       align-items: center
  //       letter-spacing: 0.2px
  //       position: relative
  //       margin-right: 6px
  //       margin-top: 6px

  //       .filter_panel_name
  //         width: 190px
  //         @include ellipsis

  //       .MuiSvgIcon-root
  //         width: 25px
  //         position: absolute
  //         right: 6px
 }  //         cursor: pointer
//@include size(330px, 350px)
//@include absolute(0, -354px)

.projects_actions {
  display: flex;
  flex-direction: column;
  height: 50px;
  margin-bottom: 12px;
  margin-top: 8px;
  position: relative;
  // max-width: 1280px
  // margin: 0 auto

  .toggler {
    height: 32px;
    margin-left: 16px; }

  .user_project_select {
    grid-column: 2/7; }

  .display_type {
    grid-column: 11/12;

    .select_items {
      width: 100%; } }

  .add_btn {
    grid-column: 12/13; }

  .select_items {
    width: 400px; }

  .sub_actions {
    // grid-column: 9/12
    @include flex(normal, center);

    .columns_select {
      @include size(32px, 32px);
      margin-right: 30px;
      //color: rgba(0, 0, 0, 0.34)
      cursor: pointer;
      background-image: url('../img/bi_layout-three-columns_grey.svg');
      background-position: 0 0;
      background-size: 30px;
      background-repeat: no-repeat;

      &.active {
        background-image: url('../img/bi_layout-three-columns_blue.svg'); } }

    .visualization_button {
      color: rgba(0, 0, 0, 0.34); } }

  .filter_btn {
    //grid-column: 10/11
    // это временно, пока кнопка соседняя скрыта

    &:hover {
      background-color: rgba(27, 170, 240, 0.05); }

    svg {
      color: $blue-main; }

    &.active {
      background-color: rgba(27, 170, 240, 0.05);
      color: $blue-main; }
    //border: none
    .MuiSvgIcon-root {
      margin-right: 5px; } }

  // .filter_plate
  //   color: black
  //   cursor: default
  //   padding: 16px
  //   width: 520px
  //   position: absolute
  //   top: 75px
  //   right: 25px
  //   //background-color: greenyellow
  //   z-index: 3
  //   border-radius: 12px
  //   //right: 25px
  //   background: #ffffff
  //   box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.09)
  //   flex-direction: column
  //   @include flex

  //   .container_headline_filter
  //     display: flex
  //     //padding: 15px 0px
  //     //height: 40px
  //     .contents_key_headline
  //       width: 216px
  //       height: 40px
  //       padding: 12px 16px

  //     .contents_value_headline
  //       width: 216px
  //       display: flex
  //       padding: 12px 16px

  //       div
  //         padding: 0 10px
  //         cursor: pointer

  //         &.active
  //           color: $blue-main
  //           cursor: default

  //   .container_filter_row
  //     display: flex
  //     margin-bottom: 8px

  //     &:last-child
  //       margin-bottom: 0

  //     .filter_key
  //       width: 216px

  //       .select_items
  //         width: 216px

  //     .filter_value
  //       width: 216px
  //       margin: 0 8px

  //       .select_items
  //         width: 216px

  //     .addNewFilterButton
  //       width: 40px
  //       height: 40px
  //       border: 1px solid $blue-main
  //       color: $blue-main
  //       border-radius: 6px
  //       box-sizing: border-box
  //       padding: 7px
 }  //       cursor: pointer

.applied_filters {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  .applied_filter_item {
    display: flex;
    background: $blue_background;
    width: 227px;
    height: 32px;
    padding: 8px;
    border-radius: 20px;
    color: $blue-main;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.2px;
    position: relative;
    margin-right: 6px;
    margin-top: 6px;

    .filter_panel_name {
      width: 190px;
      @include ellipsis; }

    .MuiSvgIcon-root {
      width: 25px;
      position: absolute;
      right: 6px;
      cursor: pointer; } } }
//@include size(330px, 350px)
//@include absolute(0, -354px)

.table_head {
  line-height: 16px;
  position: sticky;
  top: 0;
  //z-index: 2
  background: #fff;
  align-items: start;
  margin-bottom: 10px;

  .task_status {
    grid-column: 8; }

  & > div {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.34);
    line-height: 16px; }

  .table_search_wrapper {
    height: 50px;
    grid-column: 1/13;
    border-bottom: $grey_border;
    font-size: inherit;
    @include flex(flex-end, center); } }

.tasks_table_content {
  height: calc(100vh - 230px);
  overflow-y: auto;
  overflow-x: hidden; }

.users_table_content {
  max-height: calc(100vh - 230px);
  overflow-y: auto;

  &.is_dep_group {
    max-height: calc(100vh - 294px); } }

.tasks_table_head {
  div {
    cursor: pointer;

    svg.MuiSvgIcon-root {
      width: 10px;
      height: 10px; } } }

.task_drag_handle {
  background-image: url('../img/six_dots.png');
  cursor: pointer;
  opacity: 0;
  transition: opacity .3s ease;
  @include size(10px, 18px);
  @include absolute(calc(50% - 9px), false, false, -20px); }

.task_tr {
  height: 72px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: relative;

  &.in_drag {
    .task_drag_handle {
      opacity: 1; } }

  &:hover {
    .task_drag_handle {
      opacity: 1; } }

  &.selected_task {
    //background: $blue_background
    background: rgba(27, 170, 240, 0.05);
    border-top: 1px solid $blue-main;
    border-bottom: 1px solid $blue-main; }

  .task_id_wrapper {
    border: 1px solid $blue-main;
    //background: $blue_background
    color: $blue-main;
    border-radius: 52px;
    font-size: 15px;
    line-height: 26px;
    text-align: center;
    @include size(48px, 26px); }

  .dead_line {
    color: #202124;
    font-size: 15px; } }

.name_cell {
  .name, .sub_info {
    @include ellipsis; }

  .name {
    color: #202124;
    font-size: 16px;
    margin-bottom: 8px; }

  .sub_info {
    color: #5F6368;
    font-size: 14px; } }

.beyollaw {
  border-radius: 50%;
  // background:
  @include size(32px, 32px); }

.task_work_loading {
  flex-wrap: wrap;
  width: 84px;
  font-size: 15px;
  @include flex(space-between);

  .task_loading {
    color: #3F445E;
    width: 100%; }

  .work_loading {
    color: $blue-main; }

  .indicator_wrapper {
    background: rgba(27, 170, 240, 0.15);
    border-radius: 4px;
    position: relative;
    margin-top: 4px;
    overflow: hidden;
    @include size(84px, 2px);

    .indicator {
      height: 2px;
      z-index: 1;
      background: $blue-main;
      @include absolute(0, false, false, 0); } } }

.date_time {
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  @include size(170px, 41px);
  @include flex;

  & > div {
    border-radius: 6px;
    line-height: 40px;
    text-align: center;
    font-size: 15px;
    color: #212121; }

  .time {
    width: 60px;
    border-left: $grey_border; }

  .date {
    flex-grow: 1; }

  &.disabled {
    background: $disabled;
    pointer-events: none;

    & > .date, .time {
      color: $disabled_text; } } }

.input_with_unit {
  border: $grey_border;
  border-radius: 6px;
  overflow: hidden;
  font-size: 15px;
  transition: border-color .2s ease, color .2s ease;
  @include size(117px, 40px);
  @include flex;

  .input_wrapper {
    flex-grow: 1;

    input {
      text-align: center;
      color: inherit;
      transition: color .2s ease;
      background: transparent;
      @include size(85px, 100%); } }

  .unit {
    border-left: $grey_border;
    width: 30px;
    text-align: center;
    line-height: 38px;
    transition: border-color .2s ease; }

  &.disabled {
    color: #21212186;
    background: $disabled;
    pointer-events: none; }

  &.is_focused {
    border-color: $blue-main;
    color: $blue-main;

    .unit {
      border-color: $blue-main; }

    input {
      color: $blue-main; } } }

.users_actions {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 24px;

  .right_part {
    display: flex;
    //justify-content: space-between

    & > .secondary {
      width: 94px;
      margin-left: 22px; }

    & > .sub_actions {
      //grid-column: 6/9
      @include flex(flex-end, center); }

    .main_title {
      //grid-column: 1/3
      @include ellipsis; }

    .add_btn {
      //grid-column: 11/12
      padding: 12px;
      width: unset;
      margin-left: 22px; } } }

//width: 130px
//margin: -35px

.user_table_wrapper {
  @include flex;

  > .userTableContent {
    width: 100%; } }

.users_table {
  flex-grow: 1;
  align-self: flex-start;

  .grid {
    grid-template-columns: repeat(3, 1fr) repeat(3, 212px); }

  .users_table_head {
    .user_name {
      grid-column: 1/4;
      padding-left: 55px; }

    .table_search_wrapper {
      grid-column: 1/7; }

    div {
      cursor: pointer;

      svg.MuiSvgIcon-root {
        width: 10px;
        height: 10px; } } } }

.user_tr {
  height: 72px;
  border-bottom: $grey_border;
  padding: 0;
  margin-left: 54px;
  position: relative;
  font-size: 15px;
  color: #5F6368;
  background-color: #fff;
  transition: background-color .2s ease;

  &:hover {
    background-color: rgba(196, 196, 196, 0.1);

    .user_tr_options {
      opacity: 1; } }

  .user_avatar {
    //@include absolute(calc(50% - 16px), false, false, -54px)
    @include absolute();
    left: -54px; }

  .name_cell {
    grid-column: 1/4;

    .name {
      font-size: 16px;
      margin-bottom: 0;
      height: 24px;
      @include flex();
      align-items: center; }

    .sub_info {
      font-size: 14px;
      color: $black-middle; } }

  .user_dep {
    padding-right: 57px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; } }

.dep_group_plate_wrapper {
 // position: relative

 //dep_group_plate
 // width: 0
 // min-width: 0
 // transition: width .2s ease, min-width .2s ease
 // height: calc(100vh - 130px)
 // // иначе длинный список отделов уходит ниже окна и скрывается
 // overflow: hidden
 // overflow-y: auto

 // > :first-child
 //   font-size: 16px
 //   line-height: 48px
 //   margin: 0 12px
 //   font-weight: 500

 // &.visible
 //   width: 270px
 //   margin-left: 24px

 // // .header
 // //   //padding: 8px 12px
 // //   height: 32px
 // //   @include flex(space-between, center)

 // //   > :first-child
 // //     @include flex()
 // //     margin: 0 12px
 // //     height: inherit
 // //     align-items: center
 // //     cursor: default

 // //     > :first-child
 // //       @include size(16px, 16px)
 // //       max-width: 100%
 // //       background-position: -3px -3px
 // //       background-size: 22px
 // //       background-repeat: no-repeat

 // //       &.deps
 // //         background-image: url('../img/departament.svg')

 // //       &.groups
 // //         background-image: url('../img/bi_people.svg')

 // //     > :last-child
 // //       max-width: 50%
 // //       margin-left: 8px
 // //       font-size: 16px
 // //       font-weight: 500
 // //       line-height: 24px
 // //       color: $black-darkest

 // //   > .text_link_action
 // //     @include size(40px, 32px)
 // //     background-image: url('../img/plus.svg')
 // //     background-position: 9px 5px
 // //     background-size: 22px
 } // //     background-repeat: no-repeat

//.search
//padding: 0 16px
//margin-bottom: 32px

.add_pannel_wrapper {
  width: 100%;
  min-height: 100vh;
  z-index: 1000;
  flex-direction: column;
  @include flex(center, center);
  @include absolute(0, false, false, 0);

  .action_btns {
    width: 700px;
    margin-top: 30px;
    margin-bottom: 20px;
    transition: transform .3s ease;
    transform: translateY(-100vh);
    @include flex(flex-end);

    .primary {
      width: 117px; }

    .secondary {
      width: 95px;
      margin-right: 12px; } }

  .in_center {
    transform: translateY(0); } }

.add_pannel {
  padding: 30px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);
  row-gap: 16px;
  margin-top: 20px;
  transition: transform .3s ease;
  transform: translateY(-100vh);
  @include grid_2_cols;
  @include size(700px, auto);

  //> .btn.primary
  //  margin-top: 32px

  .toggler {
    width: 169px; }

  .head {
    grid-column: 1/3;
    @include flex(space-between, center);

    .toggler {
      width: 311px;
      position: relative;

      &::before {
        content: '';
        background: url('../img/priority_toggler.png');
        @include size(11px, 10px);
        @include absolute(calc(50% - 5px), false, false, -110px); }

      &::after {
        content: 'Приоритет';
        line-height: 40px;
        color: #5F6368;
        @include absolute(0, false, false, -95px); } } }

  .checklist {
    grid-column: 2/3;
    width: 100px; }

  .title {
    grid-column: 1/2;
    height: 40px;
    @include flex(flex-start, center); }

  .file_list {
    grid-column: 2/3;
    display: flex;
    flex-wrap: wrap; } }

.user_add_pannel {
  grid-template-columns: 110px 200px 105px 150px;
  column-gap: 25px;

  .head {
    .main_title {
      font-size: 20px;
      color: $black-darkest; } }

  .head {
    grid-column: 1/5;
    margin-bottom: 22.5px; }

  .avatar_wrapper {
    grid-column: 3/5;
    grid-row: 2/5;
    position: relative;
    justify-self: center;
    align-self: center;
    background-image: url('../img/avatar_placeholder.png');
    @include size(120px, 120px);

    &.with_avatar {
      background-image: none; }

    img {
      border-radius: 50%;
      @include size(120px, 120px);
      object-fit: cover;
      object-position: center; }

    input[type="file"] {
      display: none; }

    label {
      border-radius: 50%;
      box-shadow: 0px 0px 4px rgba(32, 48, 90, 0.1);
      background: url('../img/camera.png') center no-repeat #FFFFFF;
      cursor: pointer;
      @include size(30px, 30px);
      @include absolute(false, false, -15px, calc(50% - 15px)); } }

  .custom_select, .multy_select {
    grid-column: 2/5;
    width: 400px; }

  .sub_title {
    margin-top: 34px;
    margin-bottom: 14px; } }

.change_pass_pannel {
  height: 288px;
  width: 378px;
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);
  row-gap: 16px;
  margin-top: 20px;
  transition: transform .3s ease;
  position: relative;

  .close_win {
    position: absolute;
    right: 14px;
    top: 15px;
    color: lightgrey;

    &:hover {
      color: grey;
      cursor: pointer; } }

  .input_div {
    margin-bottom: 8px; }

  .head {
    margin-bottom: 24px; }

  .change_pass_panel_buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .btn {
      width: 75px; }

    .text_link_action {
      padding: 12px; } } }

// .project_sections
//   .btn
//     width: 178.5px
//     margin-top: 16px
//     margin-bottom: 34px

//   li
//     min-height: 40px
//     line-height: 40px
//     margin-bottom: 8px

//     div
//       font-size: 15px
//       color: #212121
//       @include flex(false, center)

//       img
//         margin-left: 12px
//         opacity: 0
//         cursor: pointer
//         transition: opacity .1s ease

//         &:last-of-type
//           margin-left: auto

//       &:hover
//         img
//           opacity: 1

.project_files {
  height: 170px;
  position: relative;
  border: 1px dashed $blue-main;
  border-radius: 4px;
  @include flex(center); }

.task_author {
  border: $grey_border;
  border-radius: 6px;
  line-height: 40px;
  background: $disabled;
  color: #21212177;
  padding: 0 12px;
  width: 400px; }

.new_task_work_load_wrapper {
  width: 400px;
  @include flex;

  .title {
    margin-right: 44px;
    margin-left: auto; } }

.executor_with_delegate {
  grid-column: 1/3;
  @include grid_2_cols;

  & > div:not(.MuiCollapse-container) {
    grid-column: 1/3;
    @include grid_2_cols; }

  .MuiCollapse-container {
    grid-column: 1/3; }

  .MuiCollapse-wrapperInner {
    margin-top: 16px;
    @include grid_2_cols; }

  .delegate_btn {
    margin-left: 12px; }

  .executor_select_with_btn {
    width: 400px;
    //@include flex

    .delegate_btn {
      width: 40px; }

    .custom_select {
      flex-grow: 1; }

    .checkbox_item {
      height: 40px; } }

  .entrusted_select_with_btn {
    width: 400px;
    @include flex;

    .custom_select {
      flex-grow: 1; }

    .delegate_btn {
      width: 142px; } } }

.task_addictions {
  grid-column: 1/3;
  margin-top: 20px;

  .head {
    cursor: pointer;
    font-size: 15px;
    color: #454A63; }

  .toggler_container {
    margin: 16px 0;
    @include flex(space-between, center);

    .toggler {
      width: 433px; } }

  .tasks_container {
    height: 350px;
    border: $grey_border;
    border-radius: 12px;
    padding: 20px 0;
    position: relative; }

  .sections_wrapper {
    overflow-y: auto;
    height: 100%;
    margin-right: 16px; }

  .addiction_no_tasks {
    padding-left: 16px;
    opacity: .9;
    margin-bottom: 12px; } }

.addiction_section {
  color: rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
  display: inline-block;
  width: 250px;
  cursor: pointer;
  position: relative;
  padding-left: 16px;

  &::before {
    content: '';
    background-image: url('../img/blue_arrow.png');
    transition: transform .2s ease;
    @include size(12px, 8px);
    @include absolute(calc(50% - 4px), 0); }

  &.opened {
    &::before {
      transform: rotate(180deg); } } }

.section_task {
  height: 32px;
  font-size: 15px;
  color: $black;
  line-height: 32px;
  padding-left: 16px;
  cursor: pointer;
  @include ellipsis;

  &:hover {
    background: #1ba9f010;
    color: $blue-main; }

  &:last-of-type {
    margin-bottom: 12px; }

  &.plate_task {
    color: $blue-main; }

  &.selected {
    font-weight: 500;
    color: $blue-main;
    background: #1ba9f010;
    position: relative;

    &::before {
      content: '\2714';
      transform: translateY(-50%);
      @include absolute(50%, 10px); } } }

.section_task_plate {
  border-radius: 12px;
  box-shadow: 0px 0px 30px rgba(41, 47, 76, 0.1);
  background: #ffffff;
  padding: 20px;
  z-index: 1;
  @include absolute(10px, 10px);
  @include size(390px, auto);

  .section_task_name {
    font-size: 16px;
    margin-bottom: 3px;
    color: $black; }

  .section_task_description {
    color: #5F6368;
    margin-bottom: 16px;
    height: 28px;
    @include ellipsis(2); }

  .with_title {
    font-size: 15px;
    padding-left: 120px;
    color: $black;
    margin-bottom: 16px;
    position: relative;

    &::before {
      transform: translateY(-50%);
      color: #5F6368;
      font-size: 14px;
      @include absolute(50%, initial, initial, 0); } }

  .section_task_begin {
    &::before {
      content: 'Начало'; } }

  .section_task_end {
    &::before {
      content: 'Окончание'; } }

  .section_task_executor {
    &::before {
      content: 'Исполнитель'; } }

  .section_task_priority {
    color: $blue-main;

    &.priority_9 {
      color: #FF0F00; }

    &::before {
      content: 'Приоритет'; } }

  .btn {
    width: 111px;
    margin-left: auto; } }

.user_birthday {
  grid-column: 3/5;
  @include flex(flex-end);

  .date_time {
    width: 150px;
    margin-left: 20px; }

  &.disabled {
    pointer-events: none;

    .date {
      background: $disabled;
      color: $disabled_text; } } }

.user_schedule {
  grid-column: 2/5;

  &.disabled {
    .weekdays_container {
      .schedule_day {
        pointer-events: none;
        background: $disabled;

        &.selected {
          background: rgba(0, 0, 0, 0.2);
          border-color: rgba(0, 0, 0, 0.12); } } }

    .time {
      pointer-events: none;
      background: $disabled;
      color: $disabled_text; } } }

.weekdays_container {
  @include flex;

  .schedule_day {
    margin-right: 8px;
    border: $grey_border;
    border-radius: 16px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.3);
    background: #ffffff;
    transition: background .2s ease, border-color .2s ease;
    cursor: pointer;
    @include flex(center, center);
    @include size(32px, 32px);

    &.selected {
      color: #ffffff;
      background: $blue-main;
      border-color: $blue-main; } } }

.work_time_container {
  margin-top: 16px;
  //margin-bottom: 24px
  @include flex;

  .time_picker {
    margin-right: 12px; } }

.time_picker {
  border: $grey_border;
  border-radius: 6px;
  font-size: 15px;
  @include size(100px, 42px);
  @include flex;

  .label {
    width: 30px;
    line-height: 40px;
    text-align: center;
    background: $disabled;
    color: rgba(0, 0, 0, 0.3);
    border-right: $grey_border; }

  .time {
    text-align: center;
    line-height: 40px;
    flex-grow: 1;
    color: $black; } }

.days_off {
  grid-column: 2/5;

  &.disabled {
    pointer-events: none;

    .date, .input_div {
      background: $disabled;
      color: $disabled_text; }

    .input_div {
      input {
        background: transparent; } }

    button {
      background: rgba(0, 0, 0, 0.2); } } }

.days_off_input {
  @include flex;

  .btn {
    width: 40px;
    margin-left: 12px; }

  .date_time {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 150px;

    .date {
      text-align: left;
      padding-left: 20px; } }

  .input_div {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex-grow: 1; } }

.days_off_list {
  margin-top: 12px;
  height: 40vh;
  overflow: auto; }

.dayoff_elem {
  height: 34px;
  font-size: 15px;
  color: $black;
  position: relative;
  @include flex(false, center);
  border-bottom: 1px solid rgba(0, 0, 0, 0);

  .dayoff_date {
    width: 150px;
    padding-left: 20px; }

  .dayoff_comment {
    max-width: 255px;
    padding-left: 15px;
    padding-right: 20px;
    @include ellipsis; }

  .cross {
    top: calc(50% - 5px);
    right: 0;
    opacity: 0; }

  &:hover {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    .cross {
      opacity: 1; } } }

.dep_list_elem {
  // display: flex
  // justify-content: space-between
  // height: 32px
  // margin: 6px 0
  // line-height: 30px
  // cursor: pointer
  // @include ellipsis

  // > div:first-child
  //   color: $black-darkest
  //   font-size: 14px
  //   line-height: 32px
  //   padding: 0 12px
  //   max-width: 290px
  //   @include ellipsis()

  // > .countUsers
  //   color: $black-middle
  //   height: 32px
  //   line-height: 32px
  //   margin: 0 12px
  //   text-align: center

  // > .gearIcon
  //   display: none
  //   @include size(40px, 32px)
  //   //margin: 0 12px
  //   background-image: url('../img/settings.svg')
  //   background-position: 10px 5px
  //   background-size: 20px
  //   background-repeat: no-repeat

  // &:hover
  //   > .countUsers
  //     display: none

  //   > .gearIcon
  //     display: flex

  // &.selected
  //   background: $blue-lightest

  //   > div
  //     color: $blue-main

  //   > .gearIcon
 }  //     background-image: url('../img/settings-blue.svg')

.workgroup {
  padding-left: 18px;
  font-size: 15px;
  margin-bottom: 16px; }

.add_dep_plate {
  @include flex;
  z-index: 3;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.09);
  background: #ffffff;
  padding: 16px;
  width: 350px;
  flex-direction: column;

  .input_div {
    margin-top: -15px; }

  .header {
    cursor: default;
    height: 32px;
    font-size: 14px;
    line-height: 32px;
    font-weight: 500;
    color: $black-darker;
    @include flex(space-between, center);
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;

    > div {
      color: $blue-main;
      font-weight: normal;
      margin-right: 8px; } } }

.main_header {
  height: 24px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;

  .main_title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none; }

  .close_button {
    @include size(24px, 24px);
    cursor: pointer;
    background-image: url('../img/x.svg');
    background-position: -3px -3px;
    background-size: 30px;
    background-repeat: no-repeat; }

  span {
    color: #5F6368;
    margin: 24px 0 8px;
    display: block; }

  .primary {
    align-self: flex-end; } }
//margin-top: auto
//width: 94px

.selected_dep {
  margin-bottom: 24px;
  flex-wrap: wrap;
  display: inline-flex;
  position: relative;

  .selected_dep_name {
    color: $black;
    font-size: 32px;
    margin-bottom: 8px; }

  .groups_counter {
    width: 100%;
    color: rgba(0, 0, 0, 0.3); }

  .add_to_dep {
    background: url('../img/add_to_dep.svg');
    cursor: pointer;
    margin-left: 16px;
    @include size(36px, 36px); } }

.users_list {
  margin-top: 16px;
  height: 330px;
  overflow-y: auto; }

.tr_open_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .arrow_wrapper {
    margin-left: 16px;
    cursor: pointer; }

  .tr_child_counter {
    border: 1px solid $blue-main;
    color: $blue-main;
    background-color: #ffffff;
    border-radius: 50%;
    @include size(26px, 26px);
    @include flex(center, center); }

  .child_task {
    background-image: url('../img/child_task.png');
    @include size(26px, 26px); } }

.multy_select {
  position: relative;

  .checkbox_item {
    padding-left: 5px;

    &:last-of-type {
      margin-bottom: 0; } }

  .blue_arrow {
    @include absolute(calc(50% - 4px), 12px); }

  .disabled {
    background: $disabled;
    color: $disabled_text;
    pointer-events: none; } }

.selected_items {
  flex-wrap: wrap;
  min-height: 40px;
  height: auto;
  padding-right: 28px;
  @include flex(flex-start, baseline);

  .selected_item {
    margin-right: 10px;
    height: 28px;
    background: $blue-main;
    color: #fff;
    margin: 5px;
    padding: 5px;
    border-radius: 6px;
    @include flex(flex-start, center); }

  .remove_item_btn {
    background-image: url('../img/white_cross.png');
    margin-left: 8px;
    @include size(10px, 10px); } }

.visualization_wrapper {
  //max-width: 1440px
  border-top: $grey_border;
  border-bottom: $grey_border;
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
  @include size(100%, 186px); }

.visualization {
  height: 100%;
  transition: transform .4s linear;
  @include flex;
  @include absolute(0, false, false, 0);

  .day_block {
    &:first-of-type {
      .visualization_field {
        border-left: $grey_border; } } } }

.day_block {
  transition: width .4s linear;

  .date_info {
    flex-direction: column;
    border-bottom: $grey_border;
    height: 64px;
    padding: 12px 0 8px;
    font-size: 12px;
    color: $black;
    @include flex(space-between, center);

    .week_day {
      color: rgba(0, 0, 0, 0.56); } }

  .visualization_field {
    border-right: $grey_border;
    position: relative;
    @include size(100%, 120px);

    &.weekend {
      background: rgba(0, 0, 0, 0.02); } } }

.time_line {
  height: 40px;
  background: $blue_background;
  border-radius: 6px;
  color: $blue-main;
  padding: 0 6px;
  line-height: 40px;
  transition: width .4s linear, left .4s linear;
  @include absolute(40px);
  @include ellipsis;

  &.priority_ {
    &7 {
      border: 1px solid rgba(27, 170, 240, 0.3); }

    &8 {
      border: 1px solid; }

    &9 {
      border: 2px solid; } } }

.visualization_button {
  //background: url('../img/visualization_button.png')
  opacity: .6;
  cursor: pointer;
  margin-right: 30px;
  @include size(14px, 13px);

  &.active {
    color: rgba(0, 0, 0, 1) !important; } }

.visualization_actions {
  padding: 0 24px;
  margin-bottom: 24px;

  .btn {
    width: 88px;

    &:last-of-type {
      width: 96px;
      grid-column: 12/13; } }

  .custom_select {
    grid-column: 3/4;

    .btn {
      width: 116px; } }

  .loading_date {
    flex-direction: column;
    @include flex(space-between, center);

    .loading {
      font-size: 16px;
      color: $black;
      height: 24px;
      line-height: 24px;

      span {
        color: $blue-main;
        margin-left: 12px; } }

    .date_interval {
      color: $blue-main;
      font-size: 13px; } } }

.day_close_pannel {}
// сорри но лень делать по-людски
//display: block
//width: 1204px
//min-height: 542px
//padding: 0
//.action_btns
//  width: 1204px
//  transform: none
//  padding: 0 24px
//  .btn
//    margin: 0 0 0 30px
//.main_title
//  padding: 20px 24px
//  margin-bottom: 8px
//.priority
//  justify-content: flex-end
//  & > div
//    margin-right: 0
//    margin-left: 10px

.killer_queen {
  border-radius: 6px;
  border: $grey_border;
  text-align: center;
  padding: 11px;
  transition: border-color .2s ease;
  @include flex(center, center);
  @include size(94px, 46px);

  &.with_value {
    border-color: $blue-main; }

  &.no_value {
    .value {
      color: rgba(0, 0, 0, 0.3); } }

  &.complete {
    background: rgba(33, 140, 116, 0.1);
    border: 1px solid rgba(33, 140, 116, 0.12);

    .value {
      color: #218C74; } }

  .value {
    cursor: pointer; } }

.slider_plate {
  background: #FFFFFF;
  box-shadow: 0px 0px 100px rgba(41, 47, 76, 0.1);
  border-radius: 6px;
  z-index: 1;
  padding: 24px;
  text-align: left;
  @include size(400px, 184px);
  @include absolute(65px, 0, false, false);

  .slider_value {
    display: inline-block;
    font-size: 20px;
    color: $blue-main;
    margin-bottom: 14px; }

  .actions {
    margin-top: 20px;
    @include flex(flex-end, center);

    .secondary {
      width: 94px;
      margin-left: 24px; }

    .close {
      width: 48px; } } }

.comment_cell {
  position: relative;
  @include size(100%, 100%);
  @include flex(false, center);

  .comment_btn {
    background-image: url('../img/comment_btn.png');
    cursor: pointer;
    margin-right: 8px;
    @include size(17px, 14px); }

  .comment_body {
    width: 235px; }

  .comment_area {
    background: #FFFFFF;
    box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);
    border-radius: 6px;
    z-index: 1;
    padding: 24px;
    transform: translateX(-100%);
    @include absolute(25px, false, false, 0);
    @include size(400px, 184px);

    textarea {
      resize: none;
      font: inherit;
      height: 76px;
      width: 100%;
      margin-bottom: 20px; }

    .btn {
      width: 86px;
      margin-left: auto; } } }

.text_link_action {
  align-items: center;
  display: flex;
  color: $blue-main;
  cursor: pointer;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px; }

.columnsCustomWindow {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 130px;
  right: 190px;
  z-index: 3;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.09);
  width: 260px;
  padding: 12px;
  background-color: white; }

.zeroing {
  text-decoration: none;
  color: black; }

.header_icon_round {
  width: 41px;
  height: 41px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  &.active {
    border-color: #02405E; }

  img {
    position: absolute;
    top: 5px;
    left: 4px;
    width: 30px; } }

.header_icon_borderless {
  line-height: 40px;
  cursor: pointer;
  position: relative;

  img {
    width: 30px;
    height: 30px;
    margin-left: 20px; }

  .MuiSvgIcon-root {
    position: absolute;
    width: 11px;
    height: 11px;
    left: 35px;
    top: 10px;
    color: red; } }

.notification_window {
  position: absolute;
  right: 40px;
  top: 75px;
  width: 352px;
  z-index: 150;
  border-radius: 6px;
  background-color: white;
  min-height: 100px;
  //max-height: 392px
  box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);

  .header {
    //background-color: #54C7FF
    display: flex;
    justify-content: space-between;
    padding: 8px;

    .header_text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px; } }

  .content {
    height: 383px;
    overflow-y: scroll;
    overflow-x: hidden;

    .notification_item {
      cursor: default;
      padding: 8px;
      min-height: 40px;
      display: flex;
      border-top: 1px solid rgba(0, 0, 0, 0.12);

      .avatar {
        width: 48px;

        .default_avatar {
          background: no-repeat url('../img/beyo.svg') right;
          width: 30px;
          height: 30px; }

        img {
          border-radius: 50px;
          margin: 0 6px;
          width: 32px;
          height: 32px; } }
      //background-image: url('../img/beyo.svg')
      .payload {
        width: 100%;

        .name_time {
          display: flex;
          justify-content: space-between;
          margin-bottom: 4px;

          .name_block {
            display: flex;
            font-size: 13px;
            line-height: 16px;

            .read_mark {
              width: 20px;
              cursor: pointer; }

            .MuiSvgIcon-root {
              height: 12px;
              color: $blue-main; } }

          .time_ago {
            color: rgba(0, 0, 0, 0.34);
            font-size: 12px;
            line-height: 16px; } }

        .action_text {
          cursor: pointer;
          font-size: 13px;
          line-height: 16px;
          //display: flex
          margin-bottom: 4px;
          //*
          //    display: inline
          .action {
            display: flex;
            flex-direction: column;
            margin-right: 5px;
            color: rgba(0, 0, 0, 0.34);
            display: block;
            margin: 5px;
            width: 94px;
            font-size: 13px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            padding: 0 5px;
            height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.add_comment {
              color: black;
              padding: 0;
              text-align: left;
              text-transform: initial;
              font-size: 14px;
              width: 100%;
              margin: 3px; }

            &.change_params {
              &.param {}
              &.param_value {} }

            &.status_ {}
            &.task_create {}
            &.task_delete {}
            &.dedline {} } }

        .additional_content {
          p {
            margin: 8px 0; }

          .task_status_wrapper {
            width: 94px; } }

        .task_status_wrapper {
          width: 94px; } } } } }

// .comment_list
//   .comment
//     word-break: break-all

.picture {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.picture_default{
    width:70%; 
    height:70%;
    position: absolute;
    top: 15px;
    right: 29px;
}

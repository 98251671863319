.business__drop-content {
  width: 747px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 747px;
  height: calc(100vh - 68px);
  /*****Добавил, чтоб таблица не смещалась при открытии бокового окна*****/
  // position: absolute;
  // right: 0;
  animation: 0.7s show ease; /*****анимация открытия*****/
  /**********/
  position: absolute;
  right: 0;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
  }

  & > div > div:last-child {
    border: 1px solid #e1e1e1;
    box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.15);
    padding: 35px 68px 39px 68px;
    display: flex;
    gap: 35px;
    flex-shrink: 0;
  }

  & > div > div:first-child {
    padding: 54px 40px 0 38px;
    flex-shrink: 0;

    & > form {
      margin-top: 37px;
      & > div {
        display: flex;
        flex-direction: column;
        margin-bottom: 35px;
        & > div {
          display: flex;
          flex-direction: row;
        }

        & > label {
          display: flex;
          align-items: center;
          margin-left: 8px;
          margin-bottom: 9px;
          gap: 8px;
          cursor: pointer;
        }
      }
    }
  }
}

/*****Для анимации открытия*****/
@keyframes show {
  from {
    width: 0;
  }
  to {
    width: "747px";
  }
}
/**********/

.p__drop-content {
  font-size: 14px;
  color: #292a34;
  font-weight: 400;
  line-height: 16px;
}

.input-form {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #292a34;
  font-weight: 400;
  line-height: 16px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px 4px 0px 0px;
  height: 56px;
  margin-left: 42px;
  padding: 0 9px;
  padding-left: 21px;

  &::placeholder {
    color: #292a34;
  }

  &:focus-visible {
    outline: none;
  }
}
.input__date {
  & > div {
    display: flex;
    gap: 20px;
    padding-right: 30px;
    & > input[type="date"] {
      width: 224px;
      cursor: pointer;
    }
    & > input[type="time"] {
      margin-left: 0;
      width: 116px;
      cursor: text;
    }
  }
}

input[type="time"]::-webkit-datetime-edit-text {
  padding: 0 2px;
}

#input-download {
  & > label {
    gap: 15px;
  }
  & > input {
    display: none;
  }
}

#input-download__input-label {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  width: 163px;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  margin-left: 42px;
  cursor: pointer;
}

.business__drop-content > div::-webkit-scrollbar {
  width: 7px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 3px;
}

.business__drop-content > div::-webkit-scrollbar-thumb {
  width: 7px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.businessClass {
  position: relative;
  height: 100%;

  & > div:last-child {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
  }

  & > div:first-child > p {
    text-align: right;
  }
}

#businessTask__description {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 21px;

  & > div {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #292a34;
  }

  & > div:not(:last-child) > input:focus-visible {
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
  }

  & input {
    margin-left: 8px;
    flex-grow: 1;
    background: transparent;
    border: none;
    height: 40px;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 400;
    font-weight: 400;

    &:focus-visible {
      outline: none;
      padding: 0 9px;
    }
  }
}

#bussines-next {
  width: 218px;
}

.file-download {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
  margin-bottom: 10px;

  &_deletedBtn {
    height: 22px;
    width: 22px;
    cursor: pointer;
    color: red;
    margin-left: 5px;
    padding: 4px 7px;
    background-color: #e5e5e5;
    border-radius: 50%;
    &:hover {
      background-color: #b3b3b3;
    }
  }
  &_btnSubmit {
    background-color: #01b0e9;
    margin-left: 42px;
    padding: 7px 10px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 10px;
    color: #fff;
    &:hover {
      background-color: #0281ac;
    }
  }
}
.file-uploaded {
  &__block {
    margin-left: 42px;
    > h2 {
      font-weight: 600;
    }
    > ul {
      margin-bottom: 8px;
    }
    > li {
      font-size: 16px;
    }
    > a {
      color: #00c;
      line-height: 150%;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &_error {
    margin-left: 43px;
    color: #fd8484;
    font-size: 12px;
  }
}
